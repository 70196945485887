import TheCommonImage from "../../assets/images/food-and-drink/the-commons.jpg";
import AuntLucys_1 from "../../assets/images/food-and-drink/AntyLucy_1.jpg";
import AuntLucys_2 from "../../assets/images/food-and-drink/AntyLucy_2.jpg";
import AuntLucys_3 from "../../assets/images/food-and-drink/AntyLucy_3.jpg";
import AuntLucys_4 from "../../assets/images/food-and-drink/AntyLucy_4.jpg";
import AuntLucys_5 from "../../assets/images/food-and-drink/AntyLucy_5.jpg";
import AuntLucys_6 from "../../assets/images/food-and-drink/AntyLucy_6.jpg";
import AuntLucys_7 from "../../assets/images/food-and-drink/AntyLucy_7.jpg";
import AuntLucys_8 from "../../assets/images/food-and-drink/AntyLucy_8.jpg";
import AuntLucys_9 from "../../assets/images/food-and-drink/AntyLucy_9.jpg";
import AuntLucys_10 from "../../assets/images/food-and-drink/AntyLucy_10.png";
import AuntLucys_11 from "../../assets/images/food-and-drink/AntyLucy_11.jpg";
import AuntLucys_12 from "../../assets/images/food-and-drink/AntyLucy_12.jpg";
import AuntLucys_13 from "../../assets/images/food-and-drink/AntyLucy_13.png";
import AuntLucys_14 from "../../assets/images/food-and-drink/AntyLucy_14.jpg";
import AuntLucys_15 from "../../assets/images/food-and-drink/AntyLucy_15.jpg";
import AuntLucys_16 from "../../assets/images/food-and-drink/AntyLucy_16.jpg";
import AuntLucys_17 from "../../assets/images/food-and-drink/AntyLucy_17.jpg";
import AuntLucys_18 from "../../assets/images/food-and-drink/AntyLucy_18.jpg";
import AuntLucys_19 from "../../assets/images/food-and-drink/AntyLucy_19.jpg";
import AuntLucys_20 from "../../assets/images/food-and-drink/AntyLucy_20.jpg";
import AuntLucys_21 from "../../assets/images/food-and-drink/AntyLucy_21.jpg";
import AuntLucys_22 from "../../assets/images/food-and-drink/AntyLucy_22.jpg";

import Cafe_1 from "../../assets/images/food-and-drink/cafe_1.jpg";
import Cafe_2 from "../../assets/images/food-and-drink/cafe_2.jpg";
import Cafe_3 from "../../assets/images/food-and-drink/cafe_3.jpg";
import Cafe_4 from "../../assets/images/food-and-drink/cafe_4.jpg";

import WineBar1 from "../../assets/images/food-and-drink/wine-bar/wine_bar1.jpg";
import WineBar2 from "../../assets/images/food-and-drink/wine-bar/wine_bar2.jpg";
import WineBar3 from "../../assets/images/food-and-drink/wine-bar/wine_bar3.jpg";
import WineBar4 from "../../assets/images/food-and-drink/wine-bar/wine_bar4.jpg";

// import twoTwos_1 from "../../assets/images/food-and-drink/twoTwos_1.jpg";
// import twoTwos_2 from "../../assets/images/food-and-drink/twoTwos_2.jpg";
// import twoTwos_3 from "../../assets/images/food-and-drink/twoTwos_3.jpg";
// import twoTwos_4 from "../../assets/images/food-and-drink/twoTwos_4.jpg";
// import twoTwos_5 from "../../assets/images/food-and-drink/twoTwos_5.jpg";

import lobby_image_1 from "../../assets/images/food-and-drink/lobby_first_image.jpg";
import lobby_image_2 from "../../assets/images/food-and-drink/lobby_fourth_image.jpg";
import lobby_image_3 from "../../assets/images/food-and-drink/lobby_second_image.jpg";
import lobby_image_4 from "../../assets/images/food-and-drink/lobby_third_image.jpg";

import PeopleAtCounter from "../../assets/images/food-and-drink/people-at-counter.jpg";
import PeopleWalking from "../../assets/images/food-and-drink/people-walking.jpg";
import PouringSteamedMilk from "../../assets/images/food-and-drink/pouring-steamed-milk.jpg";
import PouringSteamedMilkBW from "../../assets/images/food-and-drink/pouring-steamed-milk-bw.jpg";

const eatItems: any[] = [];

const drinkItems: any[] = [];

const moreEatsAndDrinksItems: any[] = [
  {
    leftImageSrc: PeopleAtCounter,
    leftImageAlt: "Peaople at counter",
    leftImageTitle: "Peaople at counter",
    imageCardPosition: "top",
    rightImageSrc: PeopleWalking,
    rightImageAlt: "People walking",
    rightImageTitle: "People walking",
    imageSrc: PouringSteamedMilkBW,
    imageAlt: "Pouring steamed milk",
    imageTitle: "Pouring steamed milk",
    mobileImageSrc: PeopleAtCounter,
    mobileImageAlt: "Peaople at counter",
    mobileImageTitle: "Peaople at counter",
    name: "Beyond Our Four Walls",
    subName: "Toronto, ON",
    paragraph:
      "Experiencing local life is the best part of travel; find music, food, wine or events. This is our pocket guide to what’s happening in Toronto.",
    buttonText: "What's close by",
    buttonLinkTo: "/neighbourhood",
  },
  // {
  //   leftImageSrc: AuntLucys,
  //   leftImageAlt: 'Woman eating burger',
  //   leftImageTitle: 'Woman eating burger',
  //   imageCardPosition: 'top',
  //   rightImageSrc: HeadboardWithArtwork,
  //   rightImageAlt: 'Headboard with artwork',
  //   rightImageTitle: 'Headboard with artwork',
  //   imageSrc: Commons,
  //   imageAlt: 'Record player and records',
  //   imageTitle: 'Record player and records',
  //   mobileImageSrc: Commons,
  //   mobileImageAlt: 'Record player and records',
  //   mobileImageTitle: 'Record player and records',
  //   name: 'Influencer Name',
  //   subName: 'The Annex, Toronto',
  //   paragraph: 'Creative Entrepreneur & the founder of Burgers & Beets Entertainment.',
  //   buttonText: "What's close by",
  //   buttonLinkTo: "/neighbourhood"
  // },
];

const theCommonsImageDetails: any[] = [
  {
    imgSrc: TheCommonImage,
    imgAlt: "The Commons",
    imgTitle: "The Commons",
  },
  {
    imgSrc: TheCommonImage,
    imgAlt: "The Commons",
    imgTitle: "The Commons",
  },
  {
    imgSrc: TheCommonImage,
    imgAlt: "The Commons",
    imgTitle: "The Commons",
  },
];

const auntyLucysImageDetails: any[] = [
  {
    imgSrc: AuntLucys_1,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_2,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_3,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_4,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_5,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_6,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_7,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_8,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_9,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_10,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_11,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_12,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_13,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_14,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_15,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_16,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_17,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_18,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_19,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_20,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_21,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
  {
    imgSrc: AuntLucys_22,
    imgAlt: "Aunty Lucy's Burgers",
    imgTitle: "Aunty Lucy's Burgers",
  },
];

const wineBarImageDetails: any[] = [
  {
    imgSrc: WineBar1,
    imgAlt: "The Wine Bar",
    imgTitle: "The Wine Bar",
  },
  {
    imgSrc: WineBar2,
    imgAlt: "Pouring wine",
    imgTitle: "Pouring wine",
  },
  {
    imgSrc: WineBar3,
    imgAlt: "Pouring a cocktail",
    imgTitle: "Pouring a cocktail",
  },
  {
    imgSrc: WineBar4,
    imgAlt: "Garnishing a cocktail with rosemary",
    imgTitle: "Garnishing a cocktail with rosemary",
  }
];

const annexCafeImageDetails: any[] = [
  {
    imgSrc: Cafe_1,
    imgAlt: "Annex Cafe",
    imgTitle: "Annex Cafe",
  },
  {
    imgSrc: Cafe_2,
    imgAlt: "Annex Cafe",
    imgTitle: "Annex Cafe",
  },
  {
    imgSrc: Cafe_3,
    imgAlt: "Annex Cafe",
    imgTitle: "Annex Cafe",
  },
  {
    imgSrc: Cafe_4,
    imgAlt: "Annex Cafe",
    imgTitle: "Annex Cafe",
  },
];

const twoTwosImageDetails: any[] = [
  {
    imgSrc: lobby_image_1,
    imgAlt: "lobby",
    imgTitle: "lobby",
  },
  {
    imgSrc: lobby_image_2,
    imgAlt: "lobby",
    imgTitle: "lobby",
  },
  {
    imgSrc: lobby_image_3,
    imgAlt: "lobby",
    imgTitle: "lobby",
  },
  {
    imgSrc: lobby_image_4,
    imgAlt: "lobby",
    imgTitle: "lobby",
  },
];

export {
  eatItems,
  drinkItems,
  moreEatsAndDrinksItems,
  theCommonsImageDetails,
  auntyLucysImageDetails,
  wineBarImageDetails,
  annexCafeImageDetails,
  twoTwosImageDetails,
};
