import { useEffect, useState } from "react";
import CallToActionBanner from "../../components/CallToActionBanner/CallToActionBanner";
import InfoImageGroupCarousel from "../../components/Global/InfoImageGroupCarousel/InfoImageGroupCarousel";
import ImageGroup from "../../components/ImageGroup/ImageGroup";
import RestaurantInfo from "../../components/RestaurantInfo/RestaurantInfo";
import {
  eatItems,
  drinkItems,
  moreEatsAndDrinksItems,
  // theCommonsImageDetails,
  // auntyLucysImageDetails,
  wineBarImageDetails,
  annexCafeImageDetails,
  twoTwosImageDetails,
} from "./FoodAndDrinksMockData";
import LeftColumnImage from "../../assets/images/food-and-drink/left-column-image.jpg";
import RightColumnImage from "../../assets/images/food-and-drink/right-column-image.jpg";
import RightColumnBottomLeftImage from "../../assets/images/food-and-drink/right-column-bottom-left-image.jpg";
import RightColumnBottomRightImage from "../../assets/images/food-and-drink/right-column-bottom-right-image.jpg";

import EatDrink from "../../assets/images/food-and-drink/eat_drink.jpeg";

import LobbyBarMenu from "../../assets/pdfs/LobbyBarMenu.pdf";
import DrinkMenu from "../../assets/pdfs/AnnexHotel-DrinksMenu-DIGITAL-Jan2024.pdf";
import CafeMenu from "../../assets/pdfs/Annex-Cafe.pdf";
import RaffaellaMenu from "../../assets/pdfs/Raffaella_Final_Menu_Print.pdf";

import "./FoodAndDrinks.scss";
import MetaDecorator from "../../MetaDecorator";
import PageBanner from "../../components/Global/PageBanner/PageBanner";

const FoodAndDrinks = () => {
  const [eats, setEats] = useState<any[]>([]);
  const [drinks, setDrinks] = useState<any[]>([]);
  const [moreEatsAndDrinks, setMoreEatsAndDrinks] = useState<any[]>([]);

  useEffect(() => {
    setEats(eatItems);
    setDrinks(drinkItems);
    setMoreEatsAndDrinks(moreEatsAndDrinksItems);

    return () => {
      setEats([]);
      setDrinks([]);
      setMoreEatsAndDrinks([]);
    };
  }, [eats, drinks]);

  return (
    <div className="food-and-drink">
      <MetaDecorator title="Food & Drinks" description="Sample description" />
      <PageBanner
        title="Eat and Drink"
        type="FoodAndDrinks"
        imgSrc={EatDrink}
        showHeroMask={true}
      />
      <div className="food-and-drink__eats-drinks">
        <h2 className="food-and-drink__title">Drinks</h2>
        <div className="food-and-drink__eats-list">
          <RestaurantInfo
            // imgSrc={AnnexCafeImage}
            // imgAlt="Restaurant Info"
            // imgTitle="Restaurant Info"
            id="Two"
            imgDetails={twoTwosImageDetails}
            restaurantName="Lobby"
            viewMenuLinkTo={LobbyBarMenu}
            viewMenuSecondLinkTo={DrinkMenu} //"/events"
            paragraphChildren={
              <>
                <p className="annex-restaurant-info__paragraph">
                  The heartbeat of the hotel and home to our cafe. This is the
                  type of place where your morning coffee turns into your 5
                  o'clock martini. Our space is full of art that inspires us, a
                  curated wine list and even some goodies you can take home to
                  remember your time at the annex. Our restaurant Raffaella
                  provides our snack menu, serving classic snacks that pair well
                  with good conversation in a perfectly lit setting.
                </p>
              </>
            }
            hoursChildren={
              <>
                <p className="annex-restaurant-info__hour-times">
                  Cafe 8am - 5pm Everyday
                </p>
                <p className="annex-restaurant-info__hour-times">
                  Lobby Bar 5pm - 11pm Everyday
                </p>
              </>
            }
            isBookingFull={true}
          />
        </div>
      </div>
      <div className="food-and-drink__image-group">
        <ImageGroup
          LeftColumnImgSrc={LeftColumnImage}
          LeftColumnImgAlt="Left Column"
          LeftColumnImgTitle-="Left Column"
          RightColumnTopImgSrc={RightColumnImage}
          RightColumnTopImgAlt="Right Column"
          RightColumnTopImgTitle="Right Column"
          RightColumnBottomLeftImgSrc={RightColumnBottomLeftImage}
          RightColumnBottomLeftImgAlt="Right Column"
          RightColumnBottomLeftImgTitle="Right Column"
          RightColumnBottomRightImgSrc={RightColumnBottomRightImage}
          RightColumnBottomRightImgAlt="Right Column"
          RightColumnBottomRightImgTitle="Right Column"
        />
      </div>

      <div className="food-and-drink__eats-drinks">
        <h2 className="food-and-drink__title">Eat</h2>
        <div className="food-and-drink__eats-list">
          {/*<RestaurantInfo*/}
          {/*  // imgSrc={TheCommonImage}*/}
          {/*  // imgAlt="Restaurant Info"*/}
          {/*  // imgTitle="Restaurant Info"*/}
          {/*  imgDetails={theCommonsImageDetails}*/}
          {/*  restaurantName="The Commons"*/}
          {/*  paragraphChildren={*/}
          {/*    <>*/}
          {/*      <p className="annex-restaurant-info__paragraph">*/}
          {/*        The Wine Bar is a cozy, intimate room that warmly invites you*/}
          {/*        in for a drink and great conversation.*/}
          {/*      </p>*/}
          {/*      <p className="annex-restaurant-info__paragraph">*/}
          {/*        Enjoy a wine from our curated list of biodynamic and natural*/}
          {/*        wines, or a mouthwatering cocktail from the bar. With a space*/}
          {/*        this enticing you just may end up spending the night.*/}
          {/*      </p>*/}
          {/*    </>*/}
          {/*  }*/}
          {/*  viewMenuLinkTo="/pdf/NutritionFactsEN.pdf"*/}
          {/*  hoursChildren={*/}
          {/*    <>*/}
          {/*      <p className="annex-restaurant-info__hour-times">*/}
          {/*        Mon - Sun : 3pm - late*/}
          {/*      </p>*/}
          {/*    </>*/}
          {/*  }*/}
          {/*  isBookingFull={false}*/}
          {/*  message="First Come. First Served."*/}
          {/*/>*/}
          <RestaurantInfo
            id="cafe"
            imgDetails={annexCafeImageDetails}
            restaurantName="Annex Cafe"
            paragraphChildren={
              <>
                <p className="annex-restaurant-info__paragraph">
                  The Annex Cafe features a rotational selection of hot/cold
                  drinks and food from the city’s best providers. We are
                  currently serving coffee from award-winning producer, The
                  Angry Roaster and pastries from Petite Thuet. The cafe is
                  situated in our Lobby, the perfect place to get some work done
                  or catch up with a friend. We'd love to hang out with you, but
                  you can take your coffee to go if you must.
                </p>
              </>
            }
            viewMenuLinkTo={CafeMenu}
            hoursChildren={
              <>
                <p className="annex-restaurant-info__hour-times">
                  8am - 5pm Everyday
                </p>
              </>
            }
            isBookingFull={true}
            message="First Come. First Served."
          />

          <RestaurantInfo
            // imgSrc={TheWineBarImage}
            // imgAlt="Restaurant Info"
            // imgTitle="Restaurant Info"
            id="wine-bar"
            imgDetails={wineBarImageDetails}
            restaurantName="Raffaella"
            paragraphChildren={
              <>
                <p className="annex-restaurant-info__paragraph">
                  Born from the vision of founder Simon Vickerson, Raffaella is
                  a family style Italian restaurant in The annex hotel. Simon’s
                  special relationship with his Grandmother Raffaella is what we
                  pay homage to every time you dine with us. We serve nostalgic
                  Italian dishes with parts of her legendary recipes that remind
                  someone of home. A plate at Raffaella is meant to warm the
                  soul as much as the belly. Whether dining with a group of 10+
                  or alone, everyone who steps foot in Raffaella is welcomed as
                  if they’re returning for the Holidays after a year away.
                </p>
              </>
            }
            viewMenuLinkTo={RaffaellaMenu}

            hoursChildren={
              <>
                <p className="annex-restaurant-info__hour-times">
                  Wed - Sat 5pm - 11pm
                </p>
                <p className="annex-restaurant-info__hour-times">
                  Sun - Tue Closed
                </p>
              </>
            }
            isBookingFull={false}
            message={"Book now on RESY ->"}
            textUs={true}
            onBookATableClick={() =>
              window.open(
                "https://resy.com/cities/toronto-on/venues/raffaella?seats=2"
              )
            }
          />
          {/* hide anuty lucys */}
          {/* <RestaurantInfo
            // imgSrc={AnutyLucyBurgersImage}
            // imgAlt="Restaurant Info"
            // imgTitle="Restaurant Info"
            id="aunty-lucys"
            imgDetails={auntyLucysImageDetails}
            restaurantName="Aunty Lucy’s"
            paragraphChildren={
              <>
                <p className="annex-restaurant-info__paragraph">
                  This food pairs well with an ice cold beverage and a good
                  vibe. Named after founder Chieff Bosompra's grandmother, Aunty
                  Lucy's eats will satisfy your soul as much as your hunger.
                  Enjoy Toronto's best smash burgers, fried chicken and more
                  with a quick order from your phone.
                </p>
              </>
            }
            viewMenuLinkTo="/pdf/NutritionFactsEN.pdf"
            hoursChildren={
              <>
                <p className="annex-restaurant-info__hour-times">
                Permanently closing Aug 31st
                </p>
              </>
            }
            isBookingFull={true}
            message="First Come. First Served."
            menuUrl={"https://auntylucysburgers.com/menu/"}
          /> */}
        </div>
      </div>

      <div className="food-and-drink__info-image-group-carousel">
        <InfoImageGroupCarousel
          title="Looking for something else?"
          items={moreEatsAndDrinks}
        />
      </div>

      <CallToActionBanner
        title="Got a question? Get an answer."
        body="Reservations? Menu questions? Need the recipe for that drink from last night? Text us."
        buttonText="Send us a message"
        buttonHoverText="+1 647 694 9868"
        buttonClassName="sms-button"
        hasATag={true}
      />
    </div>
  );
};

export default FoodAndDrinks;
